<template>
  <v-navigation-drawer
    :value="isSidebarSendActive"
    temporary
    touchless
    :right="!$vuetify.rtl"
    width="350"
    app
    @input="val => $emit('update:is-sidebar-send-active', val)"
  >
    <v-card height="100%">
      <div class="drawer-header d-flex align-center">
        <h4 class="font-weight-semibold text-base">
          Enviar por Email
        </h4>
        <v-spacer></v-spacer>
        <v-btn
          icon
          small
          @click="$emit('update:is-sidebar-send-active', false)"
        >
          <v-icon size="22">
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.email"
                outlined
                :label="$t('lbl.para')"
                dense
                hide-details="auto"
                :rules="emailRules"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <v-text-field
                v-model="clientCarShop.subject"
                outlined
                :label="$t('lbl.subject')"
                dense
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="12"
            >
              <tiptap-vuetify
                v-model="clientCarShop.message"
                :extensions="extensions"
                :placeholder="`${$t('lbl.message')}...`"
              />
            </v-col>
          </v-row>

          <v-chip
            color="primary"
            small
            label
            class="v-chip-light-bg primary--text mb-6 mt-2"
          >
            {{ $t('lbl.adjuntCotization') }}
          </v-chip>

          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="error"
                block
                @click="$emit('update:is-sidebar-send-active', false)"
              >
                {{ $t('btn.cancele') }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-btn
                color="primary"
                outlined
                block
                :disabled="!clientCarShop.email || !clientCarShop.subject || !clientCarShop.message"
                :loading="loading"
                @click="sendEmail"
              >
                {{ $t('btn.send') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import { mdiClose } from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,

  // BulletList,
  // OrderedList,
  // ListItem,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'

export default {
  components: {
    TiptapVuetify,
  },
  model: {
    prop: 'isSidebarSendActive',
    event: 'update:is-sidebar-send-active',
  },
  props: {
    isSidebarSendActive: {
      type: Boolean,
      required: true,
    },
    codeReserva: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      extensions: [
        // BulletList,
        // OrderedList,
        // ListItem,
        Italic,
        Bold,
        HardBreak,
      ],
      icons: {
        mdiClose,
      },
      emailRules: [v => this.isEmail(v) || 'Email debe ser válido'],
    }
  },
  computed: {
    ...mapState({
      clientCarShop: state => state.app.clientCarShop,
      loadingBtn: state => state.app.loadingBtn,
      carItems: state => state.app.carItems,
      itemsCheckCobros: state => state.app.itemsCheckCobros,
    }),
    message: {
      get() {
        const msg = `<p>Hola, le envío esta cotización
          <br /><br />
          <span style="margin-left: 10px">Saludos Cordiales</span>
      </p>`

        return msg
      },
    },
  },

  created() {
    this.clientCarShop.subject = 'Cotización'
    this.clientCarShop.message = this.message
    this.clientCarShop.email = this.emailConciliacion
  },
  methods: {
    ...mapMutations(['setCheckAllCobros', 'updateItemCheckCobros']),
    isEmail(str) {
      const res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      try {
        if (str === '' || str === null || str === undefined) {
          return true
        }
        if (res.test(str)) {
          return true
        }

        return false
      } catch (_) {
        return false
      }
    },
    sendEmail() {
      const json = {
        subject: this.clientCarShop.subject,
        message: this.clientCarShop.message,
        email: this.clientCarShop.email,
        action: 'send',
        codeReserva: this.codeReserva,
      }
      this.loading = true
      this.axios
        .post('reservations/send-email-adjunt', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          // eslint-disable-next-line no-empty
          if (res.data.success === true) {
            this.$toast.success(this.$t('msg.msgSendEmail', { n: this.clientCarShop.email }))
            this.$emit('update:is-sidebar-send-active', false)
          }
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
