<!-- eslint-disable vue/no-v-html -->
<template>
  <fragment v-if="!isLoading">
    <v-row
      v-if="codeValid"
      class="app-invoice-preview"
    >
      <v-col
        cols="12"
        md="7"
        offset-md="2"
        offset-lg="2"
        offset-ms="2"
      >
        <v-card>
          <Pdf
            :config="config"
            :model="model"
            :email-principal="emailPrincipal"
            :cars-items="carsItems"
            :price-all-cars="priceAllCars"
            :price-all-reserve="priceAllReserve"
            :currencies="currencies"
          />
        </v-card>

        <v-card
          v-if="!loadingCond"
          class="no-printme"
        >
          <v-card-text v-if="conditions.lenght > 0">
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  Condiciones Generales
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-row no-gutters>
                    <v-col
                      cols="12"
                      md="12"
                    >
                      <div
                        v-for="(condItem, indCon) in conditions"
                        :key="indCon"
                        class="col-12"
                      >
                        <p v-html="condItem"></p>
                      </div>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- Right Column: Invoice Action -->
      <v-col
        cols="12"
        md="3"
      >
        <v-card>
          <v-card-text>
            <v-btn
              color="primary"
              class="mb-3"
              block
              @click="sendPdf"
            >
              <v-icon
                class="me-2"
                left
              >
                {{ icons.mdiSendOutline }}
              </v-icon>
              <span>{{ $t('lbl.sendCotization') }}</span>
            </v-btn>
            <v-btn
              class="mb-3"
              color="secondary"
              block
              outlined
              :loading="loadingDescargar"
              @click="downloadPdf"
            >
              {{ $t('lbl.downloadCotization') }}
            </v-btn>
            <v-btn
              class="mb-3"
              color="secondary"
              block
              outlined
              :loading="loadingImprimir"
              @click="printPdf"
            >
              {{ $t('lbl.printCotization') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      v-if="!codeValid"
      class="app-invoice-preview"
    >
      <v-col
        cols="12"
        md="4"
        offset-md="4"
        class="mt-15"
      >
        <v-card>
          <v-card-title class="d-flex align-center justify-center py-7">
            <router-link
              to="/"
              class="align-center"
            >
              <v-img
                :src="`${url_public}${config.isotipo ? config.isotipo : config.logo}`"
                max-height="250px"
                max-width="250px"
                alt="logo"
                contain
                class="me-3 "
              ></v-img><br />

              <!--<h2 class="text-2xl font-weight-semibold">
                {{ config.name }}
              </h2> -->
            </router-link>
          </v-card-title>

          <v-card-text>
            <p class="h5 text--primary mb-2 px-2">
              {{ $t('msg.msgCaducaLink1') }}
            </p>
            <p class="h5 text--primary mb-2 px-2">
              {{ $t('msg.msgCaducaLink2', { n: emailSoporte, m: phoneSoporte }) }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col
                cols="12"
                md="4"
                offset-md="4"
              >
                <v-btn
                  v-if="name_project === 'wwwww'"
                  color="primary"
                  :to="{ name: 'home' }"
                  class="mb-4"
                  block
                >
                  {{ $t('landing.menu.home') }}
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  :to="{ name: 'auth-login' }"
                  class="mb-4"
                >
                  INICIAR SESIÓN
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <SidebarSend
      v-if="isSidebarSendActive"
      v-model="isSidebarSendActive"
      :code-reserva="$route.params.code"
    />
  </fragment>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiChevronLeft, mdiEyeOutline, mdiEyeOffOutline, mdiSendOutline, mdiCurrencyUsd } from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import themeConfig from '@themeConfig'

// eslint-disable-next-line import/no-unresolved
import SidebarSend from './utils/SidebarSend.vue'
import Pdf from './utils/Pdf.vue'

export default {
  components: {
    SidebarSend,
    Pdf,
  },
  data() {
    return {
      config: {
        logo: null,
        name: '',
      },
      isLoading: true,
      isSidebarSendActive: false,
      url_public: process.env.VUE_APP_API_URL_PLUBLIC,
      name_project: process.env.VUE_APP_PROJ_NAME,
      emailSoporte: process.env.VUE_APP_SUPORT,
      phoneSoporte: process.env.VUE_APP_PHONE_SUPORT,
      isPasswordVisible: false,
      password: '',
      has_minimum_lenth: false,
      has_number: false,
      has_lowercase: false,
      has_uppercase: false,
      has_special: false,
      confirmPassword: '',
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      icons: {
        mdiChevronLeft,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiSendOutline,
        mdiCurrencyUsd,
      },
      model: {},
      carsItems: [],
      priceAllCars: 0,
      priceAllReserve: 0,
      loading: false,
      codeValid: false,
      emailPrincipal: null,
      loadingDescargar: false,
      loadingImprimir: false,
      loadingEnviar: false,
      loadingCond: true,
      conditions: [],
      currencies: [],
    }
  },
  mounted() {
    this.load()
    this.getCurrencies()
  },

  methods: {
    getCurrencies() {
      this.axios
        .get('currency_list?per_page=100000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          if (res.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
            // this.$store.commit('logout')
          } else {
            this.currencies = res.data.data
          }
        })
        .finally(() => {
          this.getConfig()
        })
    },
    getConfig() {
      this.axios
        .get('configuration-view/data-general')
        .then(res => {
          if (res.data.success) {
            this.config = res.data.data.data.data

            if (this.config.emails_noty_reserve) {
              this.config.emails_noty_reserve.forEach(element => {
                if (element.principal) {
                  this.emailPrincipal = element.email
                }
              })
            }

            this.$vuetify.theme.themes.light.primary = this.config.color
          }
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    load() {
      this.axios
        .post('view-pdf/cotization', { code: this.$route.params.code })
        .then(res => {
          if (res.data.success) {
            this.model = res.data.data.data

            this.conditions = this.model.conditions
            this.model.car_shop.forEach(element => {
              if (element.entity === 'cars') {
                this.carsItems.push(element)
                this.priceAllCars += element.data_markups.priceTotal
                this.priceAllReserve += element.data_markups.priceTotal
              } else if (element.entity === 'hotels') {
                this.carsItems.push(element)

                // this.priceAllCars += element.data_markups.priceTotal
                // this.priceAllReserve += element.data_markups.priceTotal

                this.priceAllCars += element.data_service.items.rooms[0].priceWithDescuento
                this.priceAllReserve += element.data_service.items.rooms[0].priceWithDescuento
              }
            })
            this.codeValid = true
          } else {
            this.codeValid = false
          }
        })
        .finally(() => {
          this.loadingCond = false
        })
    },

    sendPdf() {
      this.isSidebarSendActive = true
    },
    downloadPdf() {
      this.loadingDescargar = true
      const json = {
        action: 'download',
        codeReserva: this.$route.params.code,
      }
      this.axios
        .post('reservations/send-email-adjunt', json, { responseType: 'arraybuffer' })
        .then(res => {
          const filename = this.$route.params.code

          this.downloadFile(res, filename)
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => {
          this.loadingDescargar = false
        })
    },
    printPdf() {
      // this.loadingImprimir = true
      window.print()
    },

    downloadFile(response, filename) {
      const newBlob = new Blob([response.data], { type: 'application/pdf' })

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob)

        return
      }

      const data = window.URL.createObjectURL(newBlob)

      const link = document.createElement('a')

      link.href = data
      link.download = `${filename}.pdf`
      link.click()
      setTimeout(() => {
        window.URL.revokeObjectURL(data)
      }, 100)
    },
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/apps/invoice.scss';

@media print {
  .v-application {
    background: none !important;
  }
  .no-printme {
    display: none;
  }

  .app-navigation-menu,
  .v-app-bar,
  .v-footer,
  .product-buy-now,
  .app-invoice-preview.row .col-12:last-of-type {
    display: none !important;
  }

  .app-invoice-preview.row .col-12:first-child {
    max-width: 100% !important;
    flex: 1 !important;
  }

  .v-main,
  .app-content-container {
    padding: 0px !important;
  }

  .v-card {
    box-shadow: none !important;
  }

  .app-invoice-preview {
    .invoice-header,
    .payment-details,
    .invoice-total {
      &.d-flex {
        flex-direction: row !important;
      }
    }
  }
}

.containerSizeMine {
  height: max-content;
  bottom: 10px;
}

.posMine {
  position: absolute;
  bottom: 20px;
}

.posFlexMine {
  display: inline-flex;
}

.posBlockMine {
  display: inline-block;
}

.marginGenMine {
  margin: 10px 50px;
}

.marginRightMine {
  margin-right: 20px;
}

.marginRightMine50px {
  margin-right: 50px;
}

.font {
  font-family: Inter, sans-serif;
  line-height: 1.5;
  margin: 0;
}

.h2 {
  font-size: 24px;
  font-weight: bold;
}

.h3 {
  font-size: 20px;
  font-weight: bold;
}

.blackTextMine {
  color: black;
}

.grayTextMine {
  color: rgba(94, 86, 105, 0.68) !important;
}

.h1 {
  font-size: 30px;
  font-weight: bold;
}

.Mine10px {
  font-size: 10px;
}

.Mine14px {
  font-size: 12px;
}

.Mine18px {
  font-size: 14px;
}

.bold {
  font-weight: bolder;
}

.marginMine {
  margin: 0px;
  margin-bottom: 10px !important;
}

.secWidthMine {
  width: auto;
}

.secWidthMine2 {
  width: 180px;
}

.cardBackMine {
  background-color: #eceef4;
  padding: 20px;
}

.cardBackMine2 {
  background-color: #fae49a;
  padding: 20px;
}
</style>
